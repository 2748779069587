import { Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { Models } from 'appwrite';
import { Api } from './helpers/api';
import { ApplicationService } from './services/Application/application.service';
import { AuthService } from './services/Auth/auth-service';
import { InternalCommunicationService } from './services/Communication/internal-communication.service';
import { EstablishmentService } from './services/Establishment/establishment.service';
import { PackageService } from './services/Package/package.service';
import { TeamsService } from './services/Teams/teams.service';
import { Application } from './shared/objects/application';
import { Establishment } from './shared/objects/establishment';
import { UserPreferences } from './shared/objects/userPreferences';
import { environment } from 'src/environments/environment';
import { CookieConsentComponent } from "./components/public/cookie-consent/cookie-consent.component";
import { MaintenanceOverlayComponent } from "./components/public/maintenance-overlay/maintenance-overlay.component";

@Component({
  standalone: true,
  imports: [RouterOutlet, RouterModule, CookieConsentComponent, MaintenanceOverlayComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  email?: string = "";
  activeUser?: Partial<Models.User<UserPreferences>>;
  activeEstablishment?: Establishment
  showBackToDashboardConfirm: boolean = false;
  application?: Application;
  establishments: Establishment[] = [];
  currentYear?: string;
  hideHeaderAndFooter?: boolean;
  roles = [
    {
      description: "Eigenaar",
      value: "Owner"
    },
    {
      description: "Administrator",
      value: "Administrator"
    },
    {
      description: "Medewerker",
      value: "ContentCreator"
    },
    {
      description: "Viewer",
      value: "Viewer"
    }
  ];

  constructor(private teamService: TeamsService, private establishmentService: EstablishmentService, private packageService: PackageService, private communicationService: InternalCommunicationService, private authService: AuthService, public router: Router, public internalCommunication: InternalCommunicationService, private applicationService: ApplicationService) {
    this.currentYear = new Date().getFullYear().toString();

    communicationService.hideTopBarNavigation?.subscribe(hide => {
      this.hideHeaderAndFooter = hide;
    })

    this.applicationService.getApplicationContext(environment.applicationContextKey).then(context => {
      if (context) {
        this.application = context;
        this.communicationService.application.next(context);
          // this.application.isInMaintenance = false; // TODO: REMOVE
      }
    }).catch(ex => {
      // Fallback option
      this.application = {
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        isInMaintenance: true,
        maintenanceMessage: "Head4 is momenteel niet beschikbaar. Probeer het later nog eens"
      }
    });

    this.authService.isLoggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.authService.user.subscribe(user => {
          if (user) {
            this.activeUser = user;
            this.email = user.email;
            this.createEstablishmentReferencesList();
          }
        });
      }
    })

    // Set active establishment when a change is coming from the dashboard component
    this.internalCommunication.setActiveEstablishment?.subscribe(setEstablishment => {
      if(!setEstablishment){
        return;
      }
      this.setActiveEstablishmentValues(setEstablishment);
    })
  }

  signOut() {
    this.authService.logout().then(() => {
      this.communicationService.establishmentContainer?.next(undefined);
      this.authService.isLoggedIn.next(false);
      this.router.navigate(["/sign-in"]);
    });
  }

  /**
   * This method will toggle the booleans for showing the correct confirmation buttons
   */
  showDashboardConfirmationButtons(show: boolean) {
    this.showBackToDashboardConfirm = show;
  }

  /**
   * This method will be called when hitting the ignore changes button
   */
  ignoreChanges() {
    this.internalCommunication.unsavedChanges = false;
    this.showBackToDashboardConfirm = false;
  }

  setActiveEstablishmentValues(activeEstablishment: Establishment | undefined){
    if (activeEstablishment) {
      this.activeEstablishment = activeEstablishment;
      this.communicationService.activeEstablishment.next(activeEstablishment);
      this.communicationService.establishmentContainer.next({
        establishment: activeEstablishment
      });

      if(activeEstablishment?.logoReference){
        this.internalCommunication.activeEstablishmentLogoReference.next(this.establishmentService.downloadEstablishmentLogo(activeEstablishment.logoReference));
      }    

      Api.teams.list().then(teamsList => {
        var team = teamsList.teams.filter(t => t.name == activeEstablishment.establishmentName);
        if (team?.length > 0) {
          this.internalCommunication.activeTeamId?.next(team[0].$id);

          if (this.activeUser) {
            this.teamService.getMembership(team[0].$id, this.activeUser.$id!).then(membership => {
              this.communicationService.activeUserRole?.next(this.determineRole(membership?.roles ?? []));
            });
          }

          if (this.activeUser?.$id) {
            this.teamService.getMembership(team[0].$id, this.activeUser?.$id).then(membership => {
              if (membership) {
                this.internalCommunication.userIsViewer?.next(!membership.roles.includes("ContentCreator"));
              }
            });
          }
        }
      });
    }

    if (this.activeEstablishment?.$id) {
      this.packageService.getEstablishmentPackage(this.activeEstablishment.$id!).then(establishmentPackage => {
        if (!establishmentPackage) {
          return;
        }
        this.packageService.getPackageRights(establishmentPackage.packageId).then(packageRights => {
          if (packageRights) {
            this.communicationService.activePackage?.next(packageRights);
          }
        })
      });
    }
  }

  /**
   * This method will create the data list of company references
   * @returns 
   */
  createEstablishmentReferencesList() {
    if (!this.activeUser) {
      return;
    }
    this.establishmentService.getEstablishments().then(establishments => {
      this.establishments = establishments.documents;
      this.communicationService.establishments.next(establishments.documents);

      var activeEstablishment;
      // If there is no active Establishment yet, set the first as default
      if(!this.activeUser?.prefs?.activeEstablishmentId && establishments.documents.length > 0){
        activeEstablishment = [establishments.documents[0]];
        
        // Set active Establishment if prefs are present
        if(this.activeUser?.prefs){
          this.activeUser.prefs.activeEstablishmentId = activeEstablishment[0].$id;
          this.authService.updateUserPreferences(this.activeUser?.prefs);
        }
      } else {
        activeEstablishment = establishments.documents.filter(e => e.$id == this.activeUser?.prefs?.activeEstablishmentId);
      }

      this.setActiveEstablishmentValues(activeEstablishment?.length > 0 ? activeEstablishment[0] : undefined);
    });
  }

  /**
     * This method will determine the role of a teammember
     * @param roles 
     * @returns 
     */
  private determineRole(roles: string[]) {
    if (roles.includes("Owner")) {
      return this.roles[0].value;
    } else if (roles.includes("Administrator")) {
      return this.roles[1].value;
    } else if (roles.includes("ContentCreator")) {
      return this.roles[2].value;
    }

    return this.roles[3].value
  }
}
