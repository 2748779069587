
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/protected/dashboard/dashboard.component';
import { CreateTenderComponent } from './components/protected/tender/create-tender/create-tender.component';
import { EditTenderComponent } from './components/protected/tender/edit-tender/edit-tender.component';
import { AuthGuard } from './guards/auth.guard';
import { ManageTenderTemplateComponent } from './components/protected/tender/manage-tender-template/manage-tender-template.component';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'sign-in', loadComponent: () => import('./components/auth/sign-in/sign-in.component').then(m => m.SignInComponent) },
  { path: 'password-recovery', loadComponent: () => import('./components/auth/password-recovery/password-recovery.component').then(m => m.PasswordRecoveryComponent) },
  { path: 'register', loadComponent: () => import('./components/auth/sign-up/sign-up.component').then(m => m.SignUpComponent) },
  { path: 'advantages', loadComponent: () => import('./components/public/branding-page/branding-page.component').then(m => m.BrandingPageComponent) },
  { path: 'release-notes', loadComponent: () => import('./components/public/release-notes/release-notes.component').then(m => m.ReleaseNotesComponent) },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/dashboard/dashboard.component').then(m => m.DashboardComponent)
  },
  {
    path: 'privacy-statement',
    loadComponent: () => import('./components/public/privacy-statement/privacy-statement.component').then(m => m.PrivacyStatementComponent)
  },
  {
    path: 'cookie-policy',
    loadComponent: () => import('./components/public/cookie-policy/cookie-policy.component').then(m => m.CookiePolicyComponent)
  },
  {
    path: 'access-denied',
    loadComponent: () => import('./components/auth/access-denied/access-denied.component').then(m => m.AccessDeniedComponent)
  },
  {
    path: 'no-active-package',
    loadComponent: () => import('./components/public/no-package/no-package.component').then(m => m.NoPackageComponent)
  },
  {
    path: 'home',
    loadComponent: () => import('./components/public/branding-page/branding-page.component').then(m => m.BrandingPageComponent)
  },
  {
    path: 'about',
    loadComponent: () => import('./components/public/about/about.component').then(m => m.AboutComponent)
  },
  {
    path: 'contact',
    loadComponent: () => import('./components/public/contact/contact.component').then(m => m.ContactComponent)
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/onboarding/onboarding.component').then(m => m.OnboardingComponent)
  },
  {
    path: 'edit-tender',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/tender/edit-tender/edit-tender.component').then(m => m.EditTenderComponent)
  },
  {
    path: 'create-tender/:fromTemplate',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/tender/create-tender/create-tender.component').then(m => m.CreateTenderComponent)
  },
  {
    path: 'manage-template/:templateId',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/tender/manage-tender-template/manage-tender-template.component').then(m => m.ManageTenderTemplateComponent)
  },
  {
    path: 'edit-tender/:id/:fromCreate',
    canActivate: [AuthGuard],
    component: EditTenderComponent
  },
  {
    path: 'edit-tender/:id',
    canActivate: [AuthGuard],
    component: EditTenderComponent
  },
  {
    path: 'navigation-screen',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/navigation-screen/navigation-screen.component').then(m => m.NavigationScreenComponent)
  },
  {
    path        : '**',
    pathMatch   : 'full',
    loadComponent: () => import('./components/public/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
