import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Api } from '../helpers/api';
import { InternalCommunicationService } from '../services/Communication/internal-communication.service';
import { PackageService } from '../services/Package/package.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private router: Router, private packageService: PackageService, private communicationService: InternalCommunicationService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {
      Api.account.get().then(async userState => {
        if (userState) {
          if (userState.emailVerification) {
            if(!this.communicationService.activePackage.getValue() && this.communicationService.activeEstablishment.getValue()){
              var result = await this.packageService.getEstablishmentPackage(this.communicationService.activeEstablishment.getValue()?.$id!).then(establishmentPackage => {
                if (!establishmentPackage) {
                  resolve(false);
                  this.router.navigate(['/no-active-package']);
                  return Promise.resolve(false);
                }
                
                return Promise.resolve(true);
              });
              
              resolve(result);
            }

            resolve(true);
            return;
          } else {
            resolve(false);
            this.router.navigate(['/access-denied']);
          }
        } else {
          this.router.navigate(['/sign-in']);
          resolve(false);
        }
      }).catch(err => {
        this.router.navigate(['/sign-in']);
        resolve(false);
      });
    });
  }

}
