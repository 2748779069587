<div class="wrapper">
    @if(email && !hideHeaderAndFooter && this.router.url != '/dashboard' && this.router.url != '/home' && this.router.url != '/about' && this.router.url != '/contact'){
        <nav class="navbar navbar-expand-lg navbar-light bg-light hide-in-print">
            <div>
                <a class="navbar-brand"><img class="icon" src="../assets/Head4_500x500.svg"/></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            @if(activeUser?.emailVerification && this.router.url != '/dashboard' && this.router.url != '/home' && this.router.url != '/about' && this.router.url != '/contact' &&  !application?.isInMaintenance){
                <div>
                    <div class="navigation-buttons">
                        @if(activeUser?.emailVerification && this.router.url != '/dashboard' && this.internalCommunication.unsavedChanges && showBackToDashboardConfirm) {
                            <div class="navigation-button check" routerLink="/dashboard" (click)="ignoreChanges()">
                                <i class="bi bi-check-circle"></i>
                            </div>
                            <div class="navigation-button cancel" (click)="showDashboardConfirmationButtons(false)">
                                <i class="bi bi-x-circle"></i>
                            </div>
                        }
                        @if(!showBackToDashboardConfirm && this.internalCommunication.unsavedChanges){
                            <div class="navigation-button" (click)="showDashboardConfirmationButtons(true)">
                                <i class="bi bi-speedometer2"></i>
                            </div>
                        } @else if(!showBackToDashboardConfirm && !this.internalCommunication.unsavedChanges){
                            <div class="navigation-button" routerLink="/dashboard">
                                <i class="bi bi-speedometer2"></i>
                            </div>
                        }
        
                        <div class="navigation-button" routerLink="/">
                            <i class="bi bi-house"></i>
                        </div>
                    </div>
                </div>
            }
            <div class="collapse navbar-collapse profile" id="profile-div">
                @if(!email){
                    <div>
                        <button class="btn btn-success" routerLink="/sign-in">Inloggen</button>
                    </div>
                }
                @if(activeUser?.emailVerification && activeEstablishment && !application?.isInMaintenance){
                    <div class="active-establishment">
                        <div class="active-establishment-button">
                            {{activeEstablishment.establishmentName ?? "Hoofdvestiging"}}
                        </div>
                    </div>
                }
                @if(email){
                    <ul class="navbar-nav mr-auto signed-in-user">
                        <li class="nav-item">
                            <a class="nav-link active-user">{{activeUser?.name ?? email}}</a>
                        </li>
                        <li class="nav-item dropdown">
                            <div class="sing-out-button" (click)="signOut()">
                                <i class="bi bi-box-arrow-left"></i>
                            </div>
                        </li>
                    </ul>
                }
            </div>
        </nav>
    }
    @if(!application?.isInMaintenance){
        <router-outlet></router-outlet>
    }
    @if(!application?.isInMaintenance && email && !hideHeaderAndFooter && this.router.url != '/dashboard' && this.router.url != '/home'){
        <footer class="hide-in-print">
            <p>Head4 - support&#64;head4.nl - {{currentYear}}</p>
        </footer>
    }
    <app-maintenance-overlay [application]="application"></app-maintenance-overlay>
    <app-cookie-consent></app-cookie-consent>
</div>