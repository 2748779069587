import { Component, Input } from '@angular/core';
import { Application } from 'src/app/shared/objects/application';

@Component({
  selector: 'app-maintenance-overlay',
  standalone: true,
  imports: [],
  templateUrl: './maintenance-overlay.component.html',
  styleUrl: './maintenance-overlay.component.scss'
})
export class MaintenanceOverlayComponent {

  @Input() application?: Application;
}
