import { Injectable } from '@angular/core';
import { Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { ActivePackage } from 'src/app/shared/objects/activePackage';
import { Package } from 'src/app/shared/objects/package';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  /**
   * This method will get the current package of the given establishmentId
   * @param establishmentId 
   * @returns 
   */
  async getEstablishmentPackage(establishmentId: string){
    var packages = await Api.database.listDocuments<Package>(environment.database, "EstablishmentPackage", [Query.equal('establishmentId', establishmentId)]);
  
    if(packages.documents.length > 0){
      return packages.documents[0];
    }

    return undefined;
  }

  /**
   * Get the rights of the current packages
   * @param packageId 
   * @returns 
   */
  async getPackageRights(packageId: string) : Promise<ActivePackage | undefined> {
    return await Api.database.getDocument<ActivePackage>(environment.database, "ApplicationPackages", packageId);
  }
}
