import { Component } from '@angular/core';
import { CookieService } from 'src/app/services/Cookie/cookie.service';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss'
})
export class CookieConsentComponent {

  askForCookies?: boolean;

  constructor(private cookieService: CookieService) {
     // Check if user have accepted the cookies
     var consentPermitted = this.cookieService.getCookie("CONSENT_COOKIE");
     if(consentPermitted == "UNKNONW" || !consentPermitted){
       this.askForCookies = true;
     } else{
       this.askForCookies = false;
     }
  }
  
  /**
   * Save the users preference for cookie policy
   * @param preference 
   */
    saveCookiePreference(preference: string){
      this.cookieService.setCookie("CONSENT_COOKIE", preference, 365);
      this.askForCookies = false;
    }
}
