@if(askForCookies){
    <div class="cookie-consent-banner">
        <div class="cookie-consent-banner__inner">
          <div class="cookie-consent-banner__copy">
            <div class="cookie-consent-banner__header">DEZE WEBSITE GEBRUIKT COOKIES</div>
            <div class="cookie-consent-banner__description">Deze website maakt gebruik van Cookies om uw gebruikerservaring te verbeteren en om inzichten te vergaren in het gebruik en verbeterpunten van de Head4 tooling. Voor de beste website ervaring zijn deze Cookies van essentieel belang. Als u doorgaat met het gebruik van deze website dan gaat u akkoord met het door ons opgestelde Cookie beleid. Lees het uitgebreide Cookie beleid om meer informatie ter verkrijgen over het gebruik van Cookies.
                <a routerLink="/cookie-policy" routerLinkActive="active" target="_blank">Cookie</a>
            </div>
          </div>
      
          <div class="cookie-consent-banner__actions">
            <a href="#" class="cookie-consent-banner__cta" (click)="saveCookiePreference('YES')">
              Accepteren
            </a>
            <a href="#" class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary" (click)="saveCookiePreference('NO')">
              Afwijzen
            </a>
          </div>
        </div>
    </div>
}