<!-- Maintenance Window -->
@if(application?.isInMaintenance){
    <div class="overlay-maintenance">
        <div class="maintenance-pop-up">
            <div class="header-maintenance">
                <img src="../../../../assets/H4_50x50x.svg"/>
                <h3>Sorry! Momenteel is Head4 niet beschikbaar</h3>
            </div>
            <h5>Onderhoud is nodig om de tool toekomstbestendig te houden.</h5>
            @if(application?.maintenanceMessage){
                <div class="extra-message">
                    <span class="extra-header">Aanvullend bericht:</span>
                    <span>{{application?.maintenanceMessage}}</span>
                </div>
            } @else {
                <span class="caption">Probeer het later nog eens. Bij vragen neem contact op met de beheerder</span>
            }
        </div>
        <div class="context-icon">
            <i class="bi bi-tools"></i>
        </div>
    </div>
}